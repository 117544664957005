



























































import moment from 'moment';
import FormInputMixin from '@/mixins/FormInputMixin';
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import { French } from 'flatpickr/dist/l10n/fr.js';
import {
  API_DATE_FORMAT,
  API_DATETIME_FORMAT,
  FlatPickrMode
} from '@/constants';
import Vue, { VueConstructor } from 'vue';

export default (Vue as VueConstructor<
  Vue & InstanceType<typeof FormInputMixin>
>).extend({
  components: {
    flatPickr
  },
  mixins: [FormInputMixin],
  props: {
    name: {
      type: String,
      required: true
    },
    label: {
      type: String,
      default: '',
      required: false
    },
    value: {
      default: '',
      validator(value: any) {
        return (
          value === null ||
          value instanceof Date ||
          typeof value === 'string' ||
          value instanceof String ||
          value instanceof Array ||
          typeof value === 'number'
        );
      }
    },
    placeholder: {
      type: String,
      required: false,
      default: null
    },
    errors: {
      type: Object,
      default: () => {}
    },
    openDatePickerAt: {
      type: Object,
      default: () => {
        return moment();
      }
    },
    minDate: {
      type: Date,
      default: () => {
        return moment()
          .date(1)
          .month(0)
          .year(1900)
          .toDate();
      }
    },
    maxDate: {
      validator(value: any) {
        return value === 'today' || value instanceof Date;
      },
      default: () => {
        return moment()
          .date(1)
          .month(11)
          .year(2079)
          .toDate();
      }
    },
    enableTime: {
      type: Boolean,
      default: false
    },
    mode: {
      type: String,
      default: FlatPickrMode.SINGLE
    },
    optional: {
      type: Boolean,
      default: false
    },
    helpText: {
      type: String,
      default: ''
    },
    shortcuts: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      currentDate: null,
      isMobile: false
    };
  },
  computed: {
    config(): any {
      return {
        mode: this.mode,
        altFormat: this.enableTime ? 'd.m.Y H:i' : 'd.m.Y',
        altInput: true,
        dateFormat: this.enableTime ? API_DATETIME_FORMAT : API_DATE_FORMAT,
        locale: this.$i18n.locale === 'fr' ? French : null,
        minDate: this.minDate
          ? moment(this.minDate)
              .startOf('day')
              .toDate()
          : null,
        maxDate: this.maxDate,
        enableTime: this.enableTime,
        minuteIncrement: 15,
        static: true // Sinon, la sélection de mois ou d'année ne fonctionne pas sur Firefox si le champ est dans une modal
      };
    }
  },
  watch: {
    value: function() {
      this.setCurrentDate();
    }
  },
  mounted() {
    this.setCurrentDate();
    this.isMobile = (this.$refs.flatPickrInstance as any).fp.isMobile;
  },
  methods: {
    setCurrentDate() {
      if (this.mode === FlatPickrMode.SINGLE) {
        const dateAsMoment = moment(this.value, API_DATETIME_FORMAT);
        if (!dateAsMoment.isSame(moment(this.currentDate))) {
          this.currentDate = this.value
            ? moment(this.value, API_DATETIME_FORMAT).toDate()
            : null;
        }
      } else if (this.mode === FlatPickrMode.RANGE) {
        const startAsMoment = moment(this.value[0], API_DATETIME_FORMAT);
        const endAsMoment = moment(this.value[1], API_DATETIME_FORMAT);
        if (this.currentDate === null) {
          this.currentDate = [startAsMoment.toDate(), endAsMoment.toDate()];
        }
      }
    },
    onInput(selectedDates) {
      if (this.mode === FlatPickrMode.SINGLE) {
        let dateAsString = moment(selectedDates[0]).format(API_DATE_FORMAT);
        if (this.enableTime) {
          dateAsString = moment(selectedDates[0]).format(API_DATETIME_FORMAT);
        }
        this.$emit('onValueChange', dateAsString);
      } else if (this.mode === FlatPickrMode.RANGE) {
        if (selectedDates.length > 1) {
          const startAsString = moment(selectedDates[0]).format(
            API_DATE_FORMAT
          );
          const endAsString = moment(selectedDates[1]).format(API_DATE_FORMAT);
          this.$emit('onValueChange', [startAsString, endAsString]);
        }
      }
    }
  }
});
